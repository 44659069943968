import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { FourSquare } from "react-loading-indicators";
import { BlinkBlur } from "react-loading-indicators";


const HomePage = lazy(() => import("../pages/HomePage"));
const ProductsPage = lazy(() => import("../pages/ProductsPage"));
const SingleProductPage = lazy(() => import("../pages/SingleProductPage"));
const CareersPage = lazy(() => import("../pages/CareersPage"));
const ContactUsPage = lazy(() => import("../pages/ContactUsPage"));
const AboutUsPage = lazy(() => import("../pages/AboutUsPage"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const RegisterPage = lazy(() => import("../pages/RegisterPage"));
const CartsBscketPage = lazy(() => import("../pages/Carts‌BascketPage"));
const CheckoutPage = lazy(() => import("../pages/CheckoutPage"));
const CartsWishPage = lazy(() => import("../pages/CartsWishPage"));

export default function myRouter() {
  return (
    <Router>
      <Suspense
        fallback={
          <div className=" flex items-center justify-center  h-[100vh]">
           {/* <p className="mr-4">Loading</p> <FourSquare color="#000000" size="small" text="" textColor="" /> */}
           <BlinkBlur
          color="#2a4a2a"
          size="small"
          text="Loading"
          textColor="#000000"
        />
          </div>
        }
      >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products/:category/:productId" element={<ProductsPage />} />
        <Route path="/single-products/:id" element={<SingleProductPage/>} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/contactUs" element={<ContactUsPage />} />
        <Route path="/aboutUs" element={<AboutUsPage />} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/register" element={<RegisterPage/>} />
        <Route path="/products/carts" element={<CartsBscketPage/>} />
        <Route path="/products/checkouts" element={<CheckoutPage/>} />
        <Route path="/products/wishCards" element={<CartsWishPage/>} />
      </Routes>
      </Suspense>
    </Router>
  );
}
