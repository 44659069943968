import axios from "axios";
import { createContext, useState, useEffect, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import { CheckoutContext } from "./CheckoutProvider";


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState(0);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");


  const handleEmpty=()=>{
    setName("")
    setPassword(0)
    setEmail("")
  }

const Checkout=useContext(CheckoutContext)

  const handleLogOut = () => {
    toast.error(`bye 🙌 ${name}`);
    setTimeout(() => {
      localStorage.removeItem("name");
      localStorage.removeItem("token");
    }, 1500);
    handleEmpty()
    // Remove context api list checklist and local storage checklist
    Checkout.setListCheckout([])
    // localStorage.removeItem('checklist');
    // token("")
  };


  return (
    <AuthContext.Provider
      value={{
        name,
        password,
        email,
        setName,
        setPassword,
        setEmail,
        handleLogOut,
        token,
        handleEmpty
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
