import "./App.css";
import AuthProvider from "./contexts/AuthProvider";
import CheckoutProvider from "./contexts/CheckoutProvider";
import PaginateProvider from "./contexts/PaginateProvider";
import ProductProvider from "./contexts/ProductProvider";
import Router from "./providers/Router";

// import './index.css'; // Import main styles first
// // import './headerStickyScroll.css'; // Then import other specific styles
// import 'swiper/swiper.css'; // Import Swiper styles
// import 'swiper/modules/effect-fade.css';

function App() {
  return (
    <div>
      <CheckoutProvider>
        <AuthProvider>
          <ProductProvider>
            <PaginateProvider>
              <Router />
            </PaginateProvider>
          </ProductProvider>
        </AuthProvider>
      </CheckoutProvider>
    </div>
  );
}

export default App;
