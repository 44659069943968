import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ProductContext = createContext();

export default function ProductProvider({ children }) {

  const [listProduct, setProduct] = useState([]);

  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  


  const books = () => {
    setCategoriesLoading(true);
      axios.get(process.env.REACT_APP_BASE_URL+"api/books/products")
        .then((response) => {
          setCategoriesLoading(false);
          // console.log("responseeeBoooks",response);
          setCategories(response.data)
        })
        .catch((error) => {
          console.log("errrrr",error);        
        });
    };
  
  
    useEffect(()=>{
      books()
    },[])






// console.log("xxxxxxxxxxxxxx",listProduct);
  return (
        <ProductContext.Provider 
        value={{
            listProduct,setProduct,
            categories,
            categoriesLoading,
             setCategoriesLoading,
            
        }}>
        {children}
        </ProductContext.Provider>
  );
}
