import { createContext, useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

export const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
  const [listCheckout, setListCheckout] = useState([]);
  const [quantities, setQuantities] = useState({});

  const [id, setid] = useState("");
  const [name, setname] = useState("");
  const [img, setimg] = useState("");
  const [price, setprice] = useState("");

  // Wishlist
  const [listWish, setListWish] = useState([]);

  const handleSet=(staty,setStaty)=>{
    setStaty(staty)
  }

  // Checkout Function
  const addToCheckList = (id,name,img,price) => {

    const newList = {
      id:id,
      name: name,
      img: img,
      price: price,
    };
console.log('newList',newList);
    if (listCheckout.some(item => item.name === newList.name )) {
    toast.error("Already added .");
    }else{
    // setListCheckout([...listCheckout, newList]);
    // یا توی لوکال یا توی کانتکست فعلا توی جفتش داره ذخیره میشه 
    const updatedCheckoutList = [...listCheckout, newList];
    // CONTEXT API
    setListCheckout(updatedCheckoutList);
    // LOCAL 
    localStorage.setItem('checklist', JSON.stringify(updatedCheckoutList));
    }

  };

// Wishlist Function
  const addToWishList = (id,name,img,price) => {

    const newList = {
      id:id,
      name: name,
      img: img,
      price: price,
    };

    if (listWish.some(item => item.name === newList.name)) {
    toast.error("Already added .");
    }else{
      setListWish([...listWish, newList]);
    }

  };
// console.log("WWWWWI",listWish);
  return (
    <CheckoutContext.Provider
      value={{
        listCheckout,setListCheckout,
        addToCheckList,
        handleSet,
        id, setid,
        name, setname,
        img, setimg,
        price, setprice,
        quantities, setQuantities
        ,listWish, setListWish,
        addToWishList
      }}
    >
      {children}
      <Toaster position="top-left" reverseOrder={false} />

    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
